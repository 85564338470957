<template>
  <router-view />
</template>

<script>
import "@/assets/js/membership/common";

export default {
  name: "MembershipLayout",
};
</script>

<style scoped>
@import "~@/assets/css/membership/content.css";
@import "~@/assets/css/membership/common.css";
@import "~@/assets/css/membership/normalize.css";
@import "~@/assets/css/membership/custom.css";
</style>
